<template>
  <div class="contentBox">
    <div>
      <img alt="fail" src="./images/rsuccess.png" />
      <span>恭喜您，注册成功</span>
      <div class="btnArea">
        <span @click="() => this.$router.push('/login')">
          {{ count }}s返回登陆页
        </span>
      </div>
    </div>
  </div>
</template>

<script>
let timer = null
export default {
  name: 'register-fail',
  data() {
    return {
      count: '',
    }
  },
  mounted() {
    if (!timer) {
      this.count = 3
      timer = setInterval(() => {
        if (this.count > 0) {
          this.count--
        } else {
          this.$router.push('/login')
          clearInterval(timer)
        }
      }, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(timer)
  },
}
</script>

<style scoped lang="scss">
.contentBox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 284px;
    height: 284px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > span:nth-child(2) {
      font-size: 24px;
      color: #333333;
      font-weight: 600;
      margin-top: 36px;
      margin-bottom: 64px;
    }
    img {
      width: 54px;
      height: 54px;
    }
    .btnArea {
      display: flex;
      width: 100%;
      justify-content: center;
      span {
        display: inline-block;
        color: #ffffff;
        font-size: 14px;
        width: 120px;
        height: 36px;
        background: #2c68ff;
        border-radius: 8px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
      }
    }
  }
}
</style>
